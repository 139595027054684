import { ReactNode } from "react";

function Project({
  url,
  title,
  description,
}: {
  url: string;
  title: ReactNode;
  description: ReactNode;
}) {
  return (
    <section>
      <h3>
        <a href={url}>{title}</a>
      </h3>
      <p>{description}</p>
    </section>
  );
}

export default function Home() {
  return (
    <main>
      <h2 className="blurp small">Projects</h2>
      <Project
        url="https://github.com/VanuPhantom/skrive"
        title="Skrive"
        description="Sleek and secure local dosage logging in the terminal"
      />
      <Project
        url="https://github.com/VanuPhantom/googol"
        title="googol"
        description="Low-boilerplate solution for calling Google APIs"
      />
      <Project
        url="https://github.com/VanuPhantom/earst"
        title="Earst"
        description="UNIX FIFO special file abstraction for Rust"
      />
      <Project
        url="https://github.com/thomasio101/haakje"
        title="Haakje"
        description="Utilities for building better React apps with hooks"
      />
      <h2 className="blurp small">Clients</h2>
      <Project
        url="https://albird.nl"
        title="Albird"
        description="Food safety and compliance for food service and medicine"
      />
      <Project
        url="https://waldnet.nl"
        title="Wâldnet"
        description="Mobile app for a widely-used regional news source"
      />
      <h2 className="blurp small">Where to find me</h2>
      <ul>
        <li>
          <a href="https://twitter.com/VanuPhantom">
            <img className="icon" src="twitter.svg" />
            &nbsp;@VanuPhantom
          </a>
        </li>
        <li>
          <a href="https://zug.network/@VanuPhantom">
            <img className="icon" src="mastodon.svg" />
            &nbsp;VanuPhantom@zug.network
          </a>
        </li>
        <li>
          <a href="https://github.com/VanuPhantom">
            <img className="icon" src="github.svg" />
            &nbsp;@VanuPhantom
          </a>
        </li>
        <li>
          <a href="https://traewelling.de/@VanuPhantom">
            <img className="icon" src="traewelling.png" />
            &nbsp;@VanuPhantom
          </a>
        </li>
      </ul>
    </main>
  );
}
