import React from "react";
import ReactDOM from "react-dom/client";
import { Link, Route } from "wouter";
import BlogOverview from "./BlogOverview";
import BlogPost from "./BlogPost";
import Contact from "./Contact";
import Home from "./Home";
import TypeWriterCarousel from "./TypeWriterCarousel";
import "./stylesheet.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const texts = [
  "Web apps",
  "Libraries",
  "IoT applications",
  "Mobile apps",
  "NLP systems",
  "Change",
];

root.render(
  <React.StrictMode>
    <div className="container">
      <nav>
        <h1 className="blurp">
          I Build <TypeWriterCarousel {...{ texts }} />
        </h1>
        <code>Hello, World!</code> I'm Tess, you might know me as{" "}
        <span className="highlight">The&nbsp;Vanu&nbsp;Phantom</span>.
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link href="/blog">Blog</Link>
          </li>
          <li>
            <Link href="/contact">Contact</Link>
          </li>
        </ul>
      </nav>
      <Route path="/" component={Home} />
      <Route path="/blog" component={BlogOverview} />
      <Route path="/blog/:postId">
        {(params: { postId: string }) => <BlogPost {...params} />}
      </Route>
      <Route path="/contact" component={Contact} />
    </div>
  </React.StrictMode>
);
