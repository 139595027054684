import { useEffect, useState } from "react";

export default function createResourceHook<T, U>(
  getUrl: (identifier: T) => string,
  getHeaders: (identifier: T) => Record<string, string>,
  processResponse: (response: Response) => Promise<U>
): (identifier: T) => ["loading" | "error", undefined] | ["loaded", U] {
  return (identifier) => {
    const [state, setState] = useState<
      ["loading" | "error", undefined] | ["loaded", U]
    >(() => ["loading", undefined]);

    useEffect(() => {
      let cancelled = false;

      setState(["loading", undefined]);

      fetch(getUrl(identifier), { headers: getHeaders(identifier) })
        .then(processResponse)
        .then((result) => {
          if (!cancelled) setState(["loaded", result]);
        })
        .catch(() => {
          if (!cancelled) setState(["error", undefined]);
        });

      return () => {
        cancelled = true;
      };
    }, [identifier]);

    return state;
  };
}
