export default function Contact() {
  return (
    <main>
      <p>
        You can reach out to me by e-mail via{" "}
        <a href="mailto:tess@vanu.dev">tess@vanu.dev</a>.
      </p>
      <p>
        <a href="https://github.com/vanuphantom.gpg">
          Get my PGP public key here
        </a>
        .<br />
        <b>
          Please note e-mails will be signed with my Proton email address' PGP key.
        </b>
      </p>
    </main>
  );
}
