import { Link } from "wouter";
import createResourceHook from "./createResourceHook";

const useBlogPosts = createResourceHook<
  void,
  readonly Readonly<{
    id: string;
    title: string;
    date: string;
    description: string;
  }>[]
>(
  () => "/blog",
  () => ({ Accept: "application/json" }),
  (response) => response.json()
);

export default function BlogOverview() {
  const state = useBlogPosts();

  switch (state[0]) {
    case "loading":
      return <main>Loading...</main>;
    case "error":
      return <main>Whoopsie, something went wrong!</main>;
    case "loaded":
      return (
        <main>
          <ul>
            {state[1].map((post) => (
              <li>
                <Link to={`/blog/${post.id}`}>{post.title}</Link>
              </li>
            ))}
          </ul>
        </main>
      );
  }
}
