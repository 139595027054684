import { useEffect, useState } from "react";

export default function TypeWriterCarousel({
  texts,
}: {
  texts: readonly string[];
}) {
  const [[textIndex, cursorPositon], setState] = useState<
    readonly [number, number, -1 | 1]
  >([0, 0, -1]);

  useEffect(() => {
    let stop: undefined | (() => void) = undefined;

    const handle = setInterval(() => {
      setState(
        ([currentTextIndex, currentCursorPosition, currentDirection]) => {
          if (
            currentDirection === 1 &&
            currentCursorPosition === texts[currentTextIndex].length
          )
            if (currentTextIndex === texts.length - 1) {
              stop?.();
              return [
                currentTextIndex,
                currentCursorPosition,
                currentDirection,
              ];
            } else return [currentTextIndex, currentCursorPosition, -1];
          else if (currentDirection === -1 && currentCursorPosition === 0)
            return [
              currentTextIndex === texts.length - 1 ? 0 : currentTextIndex + 1,
              currentCursorPosition,
              1,
            ];
          else
            return [
              currentTextIndex,
              currentCursorPosition + currentDirection,
              currentDirection,
            ];
        }
      );
    }, 100);

    return (stop = () => clearTimeout(handle));
  }, [texts]);

  return (
    <span className={textIndex === texts.length - 1 ? "highlight" : ""}>
      {texts[textIndex].substring(0, cursorPositon)}
    </span>
  );
}
