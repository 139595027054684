import createResourceHook from "./createResourceHook";
import { useMetadata } from "./metadata";

const useBlogPost = createResourceHook(
  (postId: string) => `/blog/${postId}/content`,
  () => ({
    Accept: "application/json",
  }),
  (
    response
  ): Promise<
    Readonly<{ title: string; description: string; date: string; html: string }>
  > => response.json()
);

export default function BlogPost({ postId }: { postId: string }) {
  const state = useBlogPost(postId);
  useMetadata(state[1]?.title, state[1]?.description);

  switch (state[0]) {
    case "loading":
      return <main>Loading...</main>;
    case "error":
      return <main>Whoopsie, something went wrong!</main>;
    case "loaded":
      return <main dangerouslySetInnerHTML={{ __html: state[1].html }} />;
  }
}
