import { useEffect } from "react";
import { BehaviorSubject } from "rxjs";

const DEFAULT_TITLE = "The Vanu Phantom",
  DEFAULT_DESCRIPTION = "The Vanu Phantom's personal site";

const $metadata = new BehaviorSubject({
  title: DEFAULT_TITLE,
  description: DEFAULT_DESCRIPTION,
});

$metadata.subscribe(({ title, description }) => {
  console.log({ title, description });
  document.querySelector("[data-title]")!.innerHTML = title;
  document.querySelector("[data-description]")!.innerHTML = description;
});

export function useMetadata(
  title = DEFAULT_TITLE,
  description = DEFAULT_DESCRIPTION
) {
  useEffect(() => {
    $metadata.next({ title, description });

    return () =>
      $metadata.next({
        title: DEFAULT_TITLE,
        description: DEFAULT_DESCRIPTION,
      });
  }, [title, description]);
}
